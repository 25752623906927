import { useEffect, useState } from 'react';
import { Types } from 'src/types';
import LocationSubMenu from './LocationSubMenu';
import Watcher from 'src/partials/Watcher';
import { useModalStore } from 'src/stores/modalStore';
import useProjectStore from 'src/stores/projectStore';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loadIcon, getType } from 'src/utils/utils';

interface LocationDetailsHeaderProps {
    location: Types.Location;
}

export default function LocationDetailsHeader({ location }: LocationDetailsHeaderProps) {
    const { projects, fetchProjects } = useProjectStore((state) => state);
    const { setModalOpen } = useModalStore((state) => state);
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        if(!projects.length) fetchProjects();
    }, []);

    const project = projects.find((p) => p.id === location.project_id);

    useEffect(() => {
        ( async () => {
            const type = getType(location.type);
            const icon = await loadIcon(type.icon);
            setIcon(icon);
        })();
    }, [location.type]);

    const name = location.name || location.type || 'Location';

    return (
        <div className="flex sticky z-20 bg-white -top-5 pt-5 -mt-5">
            <LocationSubMenu location={ location } />
            
            <h2 className="text-xl font-bold text-gray-800 mb-2 md:mb-2 grow capitalize">
                <div className="flex">
                    { icon && <FontAwesomeIcon icon={icon} className="text-xl mt-1 mr-2" /> }
                    { name }
                </div>
                <div className="text-xs text-gray-600 font-thin">
                    { project?.name || 'Project: unknown' }
                </div>
            </h2>

            <div className="hidden md:block lg:mr-4">
                { location.id !== undefined && (
                    <Watcher 
                        watching={ (location.watching || 0) > 0 } 
                        location_id={ location.id }    
                    />
                )}
            </div>

            <SlIconButton 
                className=""
                name="x-lg" 
                label="Settings" 
                onClick={() => setModalOpen(false) }
            />
        </div>
    );
}