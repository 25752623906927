    import { useState, useEffect } from 'react';
    import { Types } from 'src/types';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
    import LocationTypePicker from 'src/partials/LocationTypePicker';
    import { useLocationsStore } from 'src/stores/locationStore';
    import useProjectStore from 'src/stores/projectStore';
    import { loadIcon, getType } from 'src/utils/utils';

    interface TypeFieldProps {
        location: Types.Location;
    }

    export default function TypeField({ location }: TypeFieldProps) {
        const { upsertLocation } = useLocationsStore((state) => state);
        const { permissions } = useProjectStore((state) => state);
        const [inEdit, setInEdit] = useState(false);
        const [type, setType] = useState(location.type);
        const [icon, setIcon] = useState(null);

        useEffect(() => {
            ( async () => {
                const type = getType(location.type);
                const icon = await loadIcon(type.icon);
                setIcon(icon);
            })();
        }, [type]);

        const handleChange = (location: Types.Location) => {
            setType(location.type);
            upsertLocation(location);
            setInEdit(false);
        }

        return (
            <>
                <div className="col-span-2 grid grid-cols-[auto,1fr,auto] gap-y-1 gap-x-4 w-full text-sm text-left rtl:text-right text-gray-600">
                    { !inEdit 
                        ? <div className="font-normal text-base text-gray-800 px-3 py-1 capitalize">
                            { icon && <FontAwesomeIcon icon={icon} className="text-base mr-2" /> }
                            { type }
                        </div>
                        : <div className="ml-2">
                            <LocationTypePicker 
                                location={ location } 
                                setLocation={ handleChange }
                                showLabel={ false }
                                mini={ true }
                            />
                        </div>
                    }
                </div>
                <div className="py-1">
                    { permissions?.write === true && (
                        <button 
                            className="text-xs text-blue-500 hover:text-blue-700 text-right"
                            onClick={ () => setInEdit(!inEdit) }
                        >
                            { !inEdit ? 'Edit' : 'Back'  }
                        </button>
                    )}
                </div>
            </>
        )
    }