import { useState, useEffect } from 'react';
import { SlButton } from '@shoelace-style/shoelace/dist/react';

export default function UpdateNotification() {
    const [showUpdateNotification, setShowUpdateNotification] = useState(false);

    useEffect(() => {
        const broadcast = new BroadcastChannel('sw-messages');
        broadcast.onmessage = (event) => {
            if (event.data.type === 'NEW_VERSION_AVAILABLE') {
                setShowUpdateNotification(true);
            }
        };
        return () => broadcast.close();
    }, []);

    if (!showUpdateNotification) return null;

    return (
        <div className="update-notification absolute top-0 z-50 w-full bg-commspot_medium text-white py-3 px-4">
            A new version is available: 
            <SlButton 
                size="small" 
                className="ml-2" 
                variant="primary" 
                onClick={() => window.location.reload()}
            >
                <span className="font-semibold">Reload</span>
            </SlButton>
        </div>
    );
}