
export default function AccessDenied() {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="text-center">
                <h1 className="text-4xl font-bold">Access Denied</h1>
                <p className="text-lg">Get outta here!</p>
            </div>
        </div>
    );
}