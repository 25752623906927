import { lazy, Suspense } from 'react';
import { Types } from 'src/types';
import { SlDialog, SlIconButton, SlTab, SlTabGroup, SlTabPanel } from "@shoelace-style/shoelace/dist/react";

const FurtherInfo = lazy(() => import('./infoTabs/FurtherInfo'));
const TreeAge = lazy(() => import('./infoTabs/TreeAge'));
const TreeValue = lazy(() => import('./infoTabs/TreeValue'));
const TreeCare = lazy(() => import('./infoTabs/TreeCare'));

interface ExtraInfoProps {
    open: boolean;
    close: any;
    location: Types.Location | null;
}

export default function ExtraInfo({ location, open, close }: ExtraInfoProps) {
    if (!location) return null;

    return (
        <SlDialog 
            open={open} 
            className="w-1/2"
            noHeader={true}
        >
            <div className="h-[100vh] md:h-[70vh]">
                <h2 className="px-2 py-1 text-xl font-bold mb-2 md:mb-4 flex bg-commspot_medium text-white">
                    <div>Learn more about this tree (experimental)</div>
                    <SlIconButton
                        className="ml-auto"
                        name="x-lg"
                        onClick={ close }
                    />
                </h2>

                <SlTabGroup>
                    <SlTab slot="nav" panel="info">
                        More info
                    </SlTab>
                    <SlTab slot="nav" panel="care">
                        Care
                    </SlTab>
                    <SlTab slot="nav" panel="age">
                        How old?
                    </SlTab>
                    <SlTab slot="nav" panel="value">
                        Value
                    </SlTab>

                    <SlTabPanel name="info">
                        <Suspense fallback={<div>Loading...</div>}>
                            <FurtherInfo location={ location } />
                        </Suspense>
                    </SlTabPanel>

                    <SlTabPanel name="care">
                        <Suspense fallback={<div>Loading...</div>}>
                            <TreeCare location={ location } />
                        </Suspense>
                    </SlTabPanel>

                    <SlTabPanel name="age">
                        <Suspense fallback={<div>Loading...</div>}>
                            <TreeAge location={ location } />
                        </Suspense>
                    </SlTabPanel>

                    <SlTabPanel name="value">
                        <Suspense fallback={<div>Loading...</div>}>
                            <TreeValue location={ location } />
                        </Suspense>
                    </SlTabPanel>
                </SlTabGroup>

                
            </div>
        </SlDialog>
    );
}