import { Types } from "src/types";

interface LightboxProps {
    image: Types.Image;
    closeLightbox: () => void;
    imagePath: string;
}

export default function Lightbox({ image, closeLightbox, imagePath }: LightboxProps) {
    return (
        <div 
            className="lightbox fixed top-0 left-0 w-[90vw] h-[90vh] mx-[5vw] my-[5vh] bg-black bg-opacity-80 flex rounded-lg justify-center items-center z-50" 
            onClick={closeLightbox}
        >
            <span 
                className="lightbox-close absolute top-0 right-4 text-white text-4xl cursor-pointer" 
                onClick={closeLightbox}
            >×</span>

            <img 
                className="max-h-full max-w-full"
                src={imagePath + image.url} 
                alt={image.txt} 
            />
        </div>
    );
}