import { useEffect, useMemo } from 'react';
import { Types } from 'src/types';
import { formatDateTime } from 'src/utils/utils';
import { useLocationsStore } from 'src/stores/locationStore';
import SlRelativeTime from '@shoelace-style/shoelace/dist/react/relative-time';

import AuditLog from '../audit/AuditLog';
import Watcher from 'src/partials/Watcher';
import MiniMap from '../map/MiniMap';
import Images from '../image/Images';
import LatLng from 'src/partials/LatLng';
import AttributeList from '../attributes/AttributeList';
import LocationDetailsHeader from './LocationDetailsHeader';
import Actions from '../actions/Actions';

type LocationDetailsProps = {
    location_id: number | null;
};

export default function LocationDetails({ location_id }: LocationDetailsProps) {
    const { locations } = useLocationsStore((state) => state);

    const location = useMemo(
        () => locations.find((loc: Types.Location) => loc?.id === location_id), 
        [location_id, locations]
    );

    if (!location) return null;

    const created_at = location.created_at 
        ? formatDateTime(new Date(location.created_at)) 
        : 'Unknown date';

    return (
        <>
            <LocationDetailsHeader 
                location={ location }
            />

            <div className="block md:hidden text-right mb-4 mr-2">
                { location.id !== undefined && (
                    <Watcher 
                        watching={ (location.watching || 0) > 0 } 
                        location_id={ location.id }    
                    />
                )}
            </div>

            <div className="max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 text-gray-700">
                <div>

                    <div className="border p-2 md:p-4 rounded-lg">
                        <div className="block">
                            <div className="flex items-center justify-center mx-auto">  
                                <MiniMap 
                                    location={ location }
                                    width="100%" 
                                    height={ 150 } 
                                />
                            </div>

                            <div className="text-xs text-gray-700 text-left mb-4">
                                { location.lat && location.lng &&
                                    <LatLng location={ location } />    
                                }
                            </div>
                        </div>
                        
                        <AttributeList location={ location } />

                        <div className="font-normal text-left text-xs ml-2">
                            Added: { created_at } by { location.first_name } { location.last_name } - 
                            <SlRelativeTime date={ location.created_at } />
                        </div>
                    </div>

                    <div className="hidden md:block xl:hidden mt-6">
                        { location.id && 
                            <AuditLog location={ location } /> 
                        }
                    </div>
                </div>

                <div className="">
                    { location.id && <Images location_id =  { location.id } /> }


                    { location_id !== null && 
                        <Actions location_id={ location_id } /> 
                    }
                </div>

                <div className="block md:hidden xl:block ">
                    { location.id && 
                        <AuditLog location={ location } /> 
                    }
                </div>
            </div>
        </>
    );
}