import { getFormattedAreaSize } from 'src/utils/utils';
import { SlTooltip } from '@shoelace-style/shoelace/dist/react';

interface AreaProps {
    area: number;
};

export default function Area({ area }: AreaProps) {
    return (
        <>
            <SlTooltip content="Areas are approximate based on the lat & lng polygon coordinates">
                <div scope="row" className="capitalize py-1 px-3 font-medium text-gray-900 whitespace-nowrap">
                    Area
                </div>
            </SlTooltip>

            <div className="grow py-1 first-letter:uppercase">
                { getFormattedAreaSize(area) }
                <span className="ml-2 text-red-900">(approx)</span>
            </div>

            <div className="py-1"></div>
        </>
    )
}