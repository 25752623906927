import { useState, useEffect, lazy, Suspense } from 'react';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlDialog from "@shoelace-style/shoelace/dist/react/dialog";
import useActionStore from 'src/stores/actionStore';
import useProjectStore from 'src/stores/projectStore';

const ActionEntryForm = lazy(() => import('./ActionEntryForm'));
const ActionList = lazy(() => import('./ActionList'));

interface ActionsProps {
    location_id: number;
}

export default function Actions({ location_id }: ActionsProps) {
    const { actions, fetchActions, loading } = useActionStore((state) => state);
    const { permissions } = useProjectStore((state) => state);
    const [ showActionForm, setShowActionForm ] = useState(false);
    const [ showAll, setShowAll ] = useState(false);
   
    useEffect(() => {
        fetchActions(location_id, showAll);
    }, [showAll]);

    const handleAfterHide = (event: any) => {
        if (event.target !== event.currentTarget) {
            event.preventDefault();
        } else {
            // Close the modal and clear content immediately
            setShowActionForm(false);
        }
    };

    const handleRequestClose = (event: CustomEvent) => {
        if (event.detail.source === 'overlay') event.preventDefault();
    }

    return (
        <div className="border px-2 md:px-4 py-4 rounded-lg mt-4">
            { /** Add action form modal **/ }
            { showActionForm &&
                <SlDialog 
                    noHeader={true}
                    open={ showActionForm } 
                    onSlAfterHide={ (event) => handleAfterHide(event) }
                    onSlRequestClose={ (event) => handleRequestClose(event) }
                >
                    <Suspense fallback={ <div>Loading...</div> }>
                        <ActionEntryForm 
                            location_id={ location_id } 
                            closeModal={ () => setShowActionForm(false) }
                        />
                    </Suspense>
                </SlDialog>
            }

            <div className="flex justify-between mb-2">
                <h3 className="mb-2">Actions</h3>

                <div 
                    className="ml-2 mt-1 text-xs grow text-gray-500 font-light cursor-pointer"
                    onClick={ () => setShowAll(!showAll) }
                >
                    { showAll ? 'Hide completed' : 'Show all' }
                </div>

                <div className="-mt-2 z-30 content-end">
                    <div className="text-sm text-gray-500 inline-block relative -top-3">
                        { loading 
                            ? 'Loading...' 
                            : `${actions.length} action${actions.length === 1 ? '' : 's' }` 
                        }
                    </div>

                    {/* Add new audit entry button */}
                    { permissions?.audit === true && (
                        <SlTooltip content="Add a new action" placement="top">
                            <SlIcon 
                                name="clipboard-check" 
                                onClick={ () => { setShowActionForm(true) } }
                                className="bg-blue-500 text-white hover:text-gray-200 text-xl ml-2 rounded-full p-2 shadow-lg cursor-pointer"
                            />
                        </SlTooltip>
                    )}
                </div>
            </div>

            <Suspense fallback={ <div>Loading...</div> }>
                <ActionList 
                    actions={ actions } 
                    loading={ loading }
                />
            </Suspense>
        </div>
    );
}