import { useState } from 'react';
import { Types } from 'src/types';
import { SlInput } from '@shoelace-style/shoelace/dist/react';
import { useLocationsStore } from 'src/stores/locationStore';
import useProjectStore from 'src/stores/projectStore';

interface NameFieldProps {
    location: Types.Location;
}

export default function NameField({ location }: NameFieldProps) {
    const { upsertLocation } = useLocationsStore((state) => state);
    const { permissions } = useProjectStore((state) => state);
    const [inEdit, setInEdit] = useState(false);
    const [name, setName] = useState(location.name);

    const handleEdit = () => {
        if (inEdit) upsertLocation({ ...location, name: name });
        setInEdit(!inEdit);
    }

    return (
        <>
            <div className="col-span-2 grid grid-cols-[auto,1fr,auto] gap-y-1 gap-x-4 w-full text-sm text-left rtl:text-right text-gray-600">
                { !inEdit 
                    ? <div className="font-normal text-base text-gray-800 px-3 py-1 capitalize">
                        { name }
                    </div>
                    : <div className="ml-2 col-span-2">
                        <SlInput 
                            className="w-full"
                            size="small"
                            name="description"
                            placeholder="Describe this location" 
                            value={ name }
                            onSlChange={(e) => setName(e.target.value)}
                        />
                    </div>
                }
            </div>
            <div className="py-1">
                { permissions?.write === true && (
                    <button 
                        className="text-xs text-blue-500 hover:text-blue-700 text-right"
                        onClick={ handleEdit }
                    >
                        { !inEdit ? 'Edit' : 'Save' }
                    </button>
                )}
            </div>
        </>
    )
}