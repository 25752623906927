const versions = [
    {
        ver: '0.1.2',
        date: '2024-10-21',
        desc: `
- Switched groups to projects
- Options drawer fixes
- Started onboarding code behind the scenes
        `,
    },
    {
        ver: '0.1.3',
        date: '2024-10-22',
        desc: `
- Options drawer header
- Added version info (this!)
        `,
    },
    {
        ver: '0.1.4',
        date: '2024-10-23',
        desc: `
- Fixed resizeObserver bug with attribute select overflowing parent (hopefully)
- Fixed map drag status on load
- Made default attributes populate for new locations - set in type config
- Hide app drawer on map selection
- Custom modal headers for add / edit locations
- Moved delete to the location submenu
- Type attribute lookahead
- Location details header name changes
        `,
    },
    {
        ver: '0.1.5',
        date: '2024-10-24',
        desc: `
- Fixed mini map size issue
- New loc form tidy & minor refactor
- Location details tidy
- New location header update to show type & project
- Location types now filter based on project config
- Fly to locations on project change - sweet!
- filtering location list filter types by project defined types
- Save descriptions in location details
        `,
    },
    {
        ver: '0.1.6',
        date: '2024-10-25',
        desc: `
- Project admin
- email invites
- Onboarding improvements
- Fixed fly to on polygon locations
        `,
    },
    {
        ver: '0.1.7',
        date: '2024-10-26',
        desc: `
- Click outside of modal to close supressed for some modals
- Taller & wider modals for mobile
- Default show "take a photo" on mobile
- Numeric fields can have an estimated flag
- Attribute edit refactor pt1
- Number input element to compliment slider
- Basic image lightbox
- Add files to existing location - styling needed
        `,
    },
    {
        ver: '0.1.8',
        date: '2024-10-27',
        desc: `
- Fixed number field input bug
- Delete image from location
- Are you sure dialog
- Service worker updates
- Fixed number & typeahead field reset button bugs
- Attribute picker - hide show more initially - UX experiment
- Added meta data from OS names API and MapIt - disabled for now until MapIt licensing is sorted
- Added fungi and plant types
- Fly to project button UX change
- Add spinner while new location saving
- Custom marker colours for location types
- Made map data api calls work for polygons using the centroid
        `,
    },
    {
        ver: '0.1.9',
        date: '2024-10-28',
        desc: `
- Carousel thumbnail fixes
- Location details additional refactor
- Moved add image to a modal for loc details
- moved add audit entry to a modal for loc details
- Audit log formatting improvements
- Fixed fly-to bug
        `,
    },
    {
        ver: '0.1.10',
        date: '2024-10-29',
        desc: `
- Add new group functionality
- Marker shadows and pulsing markers - will need to watch performance
- Options drawer tidying
- Version number fix
        `,
    },
    {
        ver: '0.1.11',
        date: '2024-10-31',
        desc: `
- Rounded corners on location images
- More work on creating and listing actions
        `,
    },
    {
        ver: '0.1.12',
        date: '2024-11-02',
        desc: `
- Initial version of actions - needs more UX work
- Actions in options drawer
- Some minor UX tweaks
- "I can help" button
- Improved location details type & description editing
        `,
    },
    {
        ver: '0.1.13',
        date: '2024-11-05',
        desc: `
- Header title
- Added filters to show actions on the map and drawer
- location store partial refactor
- added location store functions file and simplified the store
        `,
    },
    {
        ver: '0.1.14',
        date: '2024-11-06',
        desc: `
- Dynamic type filter in location panel 
- Project filter in location panel
- Improved hover functions for polygons
- Added project polygon showing extent of locations
- Ghost markers bugfix
- Project descriptions and tooltips
- Dynamic fontawesome icons for location types
        `,
    },
    {
        ver: '0.1.15',
        date: '2024-11-07',
        desc: `
- Action details
- Action complete button
- Improved markers for locations
- Better icon handling for FA
- Action edit currently in location context only
- Repeat data entry - logic to repeat TBD
        `,
    },
    {
        ver: '0.1.16',
        date: '2024-11-08',
        desc: `
- Versions tidy on mobile
- Header fixes
- Location loading fixes
- Repeat action mechanism
`,
    },
    {
        ver: '0.1.17',
        date: '2024-11-09',
        desc: `
- Improved pop-ups & formatting
- Added planted date attribute type
- Added land ownership type
- Added a welcome modal
- Logout confirmation improvements
- Changed to the proper login with Google button
- Fixed filtering of projects available to the user
- Improved the attribute selector
`,
    },
    {
        ver: '0.1.18',
        date: '2024-11-10',
        desc: `
- Add attribute refactor - still some minor bugs
- Made a custom geocoding button to get high accuracy location on mobile
- Pulsing marker for geolocation
- First use button to progress to the map
- Placeholder tidy for actions and audit
- Fixed mobile layout for project edit
- Project handling on map - clicks and flytos
- Added compass and navigation controls
- UX changes to the location details
- Audit log showing changes
`   
    },
    {
        ver: '0.1.19',
        date: '2024-11-11',
        desc: `
- Better loading animation
- Fixed desktop login buttons
- Better mobile app logos - can be further improved
- Edit title from within the location details
- Location image gallery and lightbox/carousel improvements
- Image credits on large images
- Location filter drawer UX refactor
`
    },   
    {
        ver: '0.1.20',
        date: '2024-11-12',
        desc: `
- Added girth attribute type
- Fixed audit log not closing on save
- Fixed estimated checkbox not saving
- Fixed new location attributes not saving
- Basic howto dialog init
`
    },
    {
        ver: '0.1.21',
        date: '2024-11-13',
        desc: `
- Added long click on map to add a location (currently 400ms, configurable) - rolled back due to UX challenges. May revisit.
- Hopefully fixed a missing layer bug
- Resurrected the fly to locations on project change - I think the upsides outweigh the downsides
- Switched number slider to a standard input for better UX
- Accepting email based invites
- Project member list tidy
`
    },
    {
        ver: '0.1.22',
        date: '2024-11-14',
        desc: `
- Onboarding updates
- Fixed long click failing on touchscreens
`
    },
    {
        ver: '0.1.23',
        date: '2024-11-16',
        desc: `
- Switched markers from pins to circles, as the images were not consistently loading on mobile
- Added group and status to invasive species types
- Customised colours and pop-up content for invasive species
`
    },
    {
        ver: '0.1.24',
        date: '2024-11-17',
        desc: `
- Performance improvements - only load location data once on load, not 4 times!
- Added litter status
- Improved invasive species polygon borders
- Some tidying of attribute values
- Highlight polygons on hover - experimental
- Fixed location details header project name bug
- Shadows for temp circles
- Brought back circle colors
- Location list highlight on hover
- Move location marker on click
`
    },
    {
        ver: '0.1.25',
        date: '2024-11-18',
        desc: `
- Better highlighting for selected locations (point markers)
- Reduced user & invite DB calls on initial load
- Action item - small UX improvements
- Minor hover UX updates
- Added land use type and attribute
- If one attribute, default to that
- Added owner and owner type attributes
- Ordered options for select attributes
- Calc area of polygon on create and show as attribute
- Location popup details defined per type in the config
- area to acre / ha conversion
`
    },
    {
        ver: '0.1.26',
        date: '2024-11-19',
        desc: `
- Typeahead lookup field improvements
- Filtering by selected attribute values - defined in the type config
- Some map function refactoring
- Project permissions - initial work
`
    },
    {
        ver: '0.1.27',
        date: '2024-11-20',
        desc: `
- Project permissions applied across all actions
- Location list filtering improvements
- Minor UX tweaks - need to make all buttons consistent at some point
- Temp function to calculate area for older locations
- Simple feature request
- Map switch fix - not the best solution, so will revisit
`
    },
    {
        ver: '0.1.28',
        date: '2024-11-21',
        desc: `
- Experimental tree info
- Fixed new user project bug
`
    },
    {
        ver: '0.1.29',
        date: '2024-11-23',
        desc: `
- Tree info fixes
- Attribute list UX tweaks
- Further info for trees - tabbed view
- Tree age info - very early version & needs some consultation and good data
- Added tree care and value placeholder tabs
- Carousel delete fixes
- Attribute data & audit changes for better perf & UX
- Project logos - upload needs a little tidying
- Project list UX changes
- Map style switcher improvements
`
    },
    {
        ver: '0.1.30',
        date: '2024-11-24',
        desc: `
- Attaching images to audits
- Pop-up improvements
- Routing
- 404 page
- Access denied page
- Guest mode map
- Allow map style to be specified in guest mode
- Guest mode link in project list
`
    },
    {
        ver: '0.2.1',
        date: '2024-11-25',
        desc: `
- Alpha release
- User ref fix on guest mode
- Disabled the sign-up button during initial alpha (Google logins also restricted)
- Project handling in guest mode
- Guest mode fly to fixes
- Service worker change detection fixes
- Project switch fix
`
    }
    ,
    {
        ver: '0.2.2',
        date: '2024-11-26',
        desc: `
- Project item refactor
- Area calcs for filtered locations
- Location area breakdown, incl filtered attributes - WIP
- Added new types and attribute types
- Sign up and onboarding fixes
`
    }
];

export default versions;
