import { useState } from 'react';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlIconButton from '@shoelace-style/shoelace/dist/react/icon-button';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlMenu from '@shoelace-style/shoelace/dist/react/menu';
import SlMenuItem from '@shoelace-style/shoelace/dist/react/menu-item';
import ConfirmationDialog from 'src/partials/ConfirmationDialog';

import { Types } from 'src/types';
import { useLocationsStore } from 'src/stores/locationStore';
import { useModalStore } from 'src/stores/modalStore';
import { useMapStore } from 'src/stores/mapStore';
import useProjectStore from 'src/stores/projectStore';

interface LocationSubMenuProps {
    location?: Types.Location;
}

export default function LocationSubMenu({ location }: LocationSubMenuProps) {
    const { deleteLocation } = useLocationsStore((state) => state);
    const { closeModal } = useModalStore((state) => state);
    const { setEditLocationMarker } = useMapStore((state) => state);
    const { permissions } = useProjectStore((state) => state);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleDelete = async () => {
        await deleteLocation(location.id);
        closeModal();
    };

    const showDeleteConfirmation = (e) => {
        e.stopPropagation();
        setShowDeleteConfirm(true);
    }

    const moveLocationMarker = (e) => {
        setEditLocationMarker(location);
        closeModal();
        e.stopPropagation();
    }

    return (
        <>
            <SlDropdown>
                <SlIconButton 
                    slot="trigger"
                    name="three-dots-vertical" 
                    label="Settings" 
                />
                <SlMenu>
                    <SlMenuItem 
                        value="edit"
                        onClick={ moveLocationMarker }
                        disabled={ !permissions?.write }
                    >
                        <SlIcon 
                            name="pencil" 
                            className="mr-4 top-0.5 relative"
                        />
                        Move the map marker
                    </SlMenuItem>

                    <SlMenuItem 
                        value="cut"
                        onClick={ showDeleteConfirmation }
                        disabled={ !permissions?.write }
                    >
                        <SlIcon 
                            name="trash3" 
                            className="mr-4 top-0.5 relative"
                        />
                        Delete this location
                    </SlMenuItem>
                </SlMenu>
            </SlDropdown>

            <ConfirmationDialog 
                isOpen={showDeleteConfirm} 
                onConfirm={handleDelete} 
                onCancel={() => setShowDeleteConfirm(false)} 
                message="Are you sure you want to delete this location?"
            />
        </>
    );
}