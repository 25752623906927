import { useEffect } from 'react';
import { config } from 'src/config/app';
import useProjectStore from 'src/stores/projectStore';

const imagePath = config.files.image_path;

export default function ProjectName() {
    const { currentProject, fetchCurrentProject } = useProjectStore((state) => state);

    useEffect(() => {
        if (!currentProject) fetchCurrentProject();
    }, [currentProject]);

    return (
        <div className="hidden md:block px-4 text-xl font-semibold opacity-90 mt-[10px]">
            { currentProject?.logo &&
                <img 
                    src={ imagePath+ currentProject?.logo } 
                    alt="Project logo" 
                    className="w-8 h-8 inline mr-2 rounded-full relative top-[-2px]" 
                />
            }
            { currentProject?.name || 'Select or create a project' }
        </div>
    )
}