import { useMemo, useState } from 'react';
import { Types } from 'src/types'
import AttributeListItem from './AttributeListItem';
import TypeField from './TypeField';
import DescriptionField from './DescriptionField';
import NameField from './NameField';
import Area from './Area';
import ExtraInfo from './ExtraInfo';
import { updatePolygonArea } from 'src/utils/mapUtils';

interface AttributeListProps {
    location: Types.Location;
};

export default function AttributeList({ location }: AttributeListProps) {
    const [showExtraInfo, setShowExtraInfo] = useState(false);
    const attributes = useMemo(() => location.attrs || [], [location.attrs]);

    if (!location) return null;

    /** temp script to update any missing area calcs on older data */
    if (location.polygon && !location.area) {
        updatePolygonArea(location);
    }
    
    return (
        <div className="py-2 bg-commspot_pale rounded-lg mb-2">
            <div className="grid grid-cols-[auto,1fr,40px] gap-y-0 w-full text-sm text-left rtl:text-right text-gray-600">
                <NameField location={ location } />
                <TypeField location={ location } />
                <DescriptionField location={ location } />

                { attributes.map((attr: any, index: number) => (
                    <AttributeListItem 
                        key={ index }
                        attr={ attr }
                        location={ location }
                    />
                ))}


                { /* @TODO:  MOVE TO NEW COMPONENT */ }
                { location.area && (
                    <Area area={ location.area } />
                )}

            </div>

            { /** Hard coding this in for trees while it's an experiment */}
            { location.type === 'tree' && (
                <div 
                    className="text-xs bg-commspot_medium shadow-lg text-white py-1 text-center mt-2 cursor-pointer"
                    onClick={ () => setShowExtraInfo(true) }
                >
                    Learn more about this location - experimental
                </div>
            )}

            { location.type === 'tree' && (
                <div className="text-xs text-gray-600 text-center mt-2">
                    Create an audit entry to update these attributes
                </div>
            )}

            { showExtraInfo && (
                <ExtraInfo 
                    open={ showExtraInfo } 
                    close={() => setShowExtraInfo(false)}
                    location={ location }
                />
            )}
        </div>
    )
}