import { Types } from 'src/types';
import { create } from 'zustand';
import { supabase } from 'src/utils/supabaseClient';
import { getCurrentUser } from 'src/utils/userUtils';
import { getPolygonArea } from 'src/utils/mapUtils';
import { getMetaData } from 'src/utils/metaData';
import { convert } from 'terraformer-wkt-parser';
import { useAttributeStore } from './attributesStore';
import { getActionLocations, getLocationLocations, getProjectLocations } from './storeFunctions/locationStoreFunctons'

async function getLocations() {
    const state = useLocationsStore.getState();

    // clear by setting locations to [] in this store
    useLocationsStore.setState({ 
        locations: [],
        filteredLocations: [],
    });

    const { showLocationType, view, restrictToBounds } = state;

    const locationGetters = {
        locations: () => getLocationLocations(view, restrictToBounds),
        actions: () => getActionLocations(view, restrictToBounds),
        projects: () => getProjectLocations(view, restrictToBounds),
    };
    
    const locations = await (locationGetters[showLocationType]?.() || Promise.resolve([]));

    if (!locations) return [];

    if (locations.error) {
        console.error('Error fetching locations:', locations.error);
        return [];
    }

    return locations.data;
}

async function getLocation(locationId: number) {
    const { data, error } = await supabase.from('locations_extended').select('*').eq('id', locationId);

    if (error) {
        console.error('Error fetching location:', error);
        return;
    }

    return data;
}

async function setAttachmentLocation(locationId: number, temp_id: string) {
    const { data, error } = await supabase
        .from('attachments')
        .update({ location_id: locationId })
        .eq('temp_id', temp_id)
        .select();

    if (error) {
        console.error('Error updating attachment location:', error);
        return;
    }

    return data;
}

// async function saveAttributes(locationId: number, attributes: Types.Attribute[]) {

//     const { data, error } = await supabase
//         .from('attributes')
//         .upsert(
//             attributes.map((attr) => ({
//                 id: attr.id || undefined,
//                 location_id: attr.location_id || locationId,
//                 name: attr.name,
//                 value: attr.value,
//                 estimated: attr.estimated,
//             })),
//             { onConflict: ['id'] }
//         )
//         .select();

//     if (error) {
//         console.error('Error saving attributes:', error);
//         return;
//     }

//     return data;
// }

const filterAttributes = (location: Types.location, remove: string[]) => {
    return Object.keys(location)
        .filter((key) => !remove.includes(key))
        .reduce((obj, key) => {
            obj[key] = location[key];
            return obj;
        }, {});
}

const filterLocations = (locations: Types.Location[], filters: Types.Filter[]) => {
    if (!locations) return [];

    return locations.filter((location) => {
        if (filters.length === 0) return true;

        return filters.every((filter) => {
            const attr = location.attrs.find((a) => a.name === filter.attr);
            if (!attr) return false;
            return attr && attr.value.toLowerCase() === filter.value.toLowerCase();
        });
    });
}

async function upsertSupabaseLocation(location: Types.Location) {
    // if location.temp_id is set, remove it before upserting
    const temp_id = location.temp_id || null

    // TODO: handle location attributes separately
    const attributes = location.attributes || null;

    // TODO: handle location polygon and point separately
    const location_point = (location.lat && location.lng) 
        ? `SRID=4326;POINT(${location.lng} ${location.lat})` 
        : null;
        
    let location_polygon = null;

    if (location.polygon) {
        const polygon = typeof location.polygon == 'string'
            ? JSON.parse(location.polygon)
            : location.polygon;

        location.area = getPolygonArea(polygon);

        location_polygon = convert({
            "type": "Polygon",
            "coordinates": polygon,
        });
    }

    const nonLocationAttributes = [
        'first_name', 
        'last_name', 
        'last_audit', 
        'watching', 
        'temp_id', 
        'attributes', 
        'location_point', 
        'location_polygon', 
        'access',
        'attrs',
    ];
    
    location = filterAttributes(location, nonLocationAttributes);

    const isNew = location.id ? false : true;

    const { data, error } = await supabase
        .from('locations') 
        .upsert(location, { returning: 'representation', onConflict: ['id'] })
        .select();

    if (error) {
        console.error('Error fetching watchlist:', error);
        return [];
    }

    const newLocation = data[0];
    const new_id = newLocation.id;

    // upsert the point and polygon data via a stored procedure
    const { error: pointError } = await supabase.rpc('upsert_location', {
        location_id: new_id,
        location_point: location_point,
        location_polygon: location_polygon
    });

    if (pointError) {
        console.error('Error upserting location point:', pointError);
        return [];
    }

    // If a temp_id is set, update the attachment location
    if (temp_id) {
        await setAttachmentLocation(new_id, temp_id);
    }

    if (isNew) {
        getMetaData(newLocation);
    }

    // Return the upserted data (which includes new IDs for inserted rows)
    return newLocation;
}

async function watchLocation(locationId: number, userRef: string) {
    // check if the user is already watching the location - watching table: user_id, location_id
    // if not, add the location to the user's watchlist
    // if yes, remove the location from the user's watchlist

    const { data, error } = await supabase
        .from('watching')
        .select('*')
        .eq('user_id', userRef)
        .eq('location_id', locationId)
        .is('deleted_at', null);

    if (error) {
        console.error('Error fetching watchlist:', error);
        return [];
    }

    if (data.length === 0) {
        // Add location to watchlist
        const { data, error } = await supabase
            .from('watching')
            .insert([{ user_id: userRef, location_id: locationId }]);

        if (error) {
            console.error('Error adding location to watchlist:', error);
            return [];
        }

        return data;
    } else {
        // Remove location from watchlist
        const { error } = await supabase
            .from('watching')
            .delete()
            .eq('user_id', userRef)
            .eq('location_id', locationId)
            .is('deleted_at', null);

        if (error) {
            console.error('Error removing location from watchlist:', error);
            return [];
        }

        return [];
    }
}

const copyLocationsToFiltered = () => {
    // get the current location store state
    const state = useLocationsStore.getState();

    // set the filtered locations to the locations
    useLocationsStore.setState({ filteredLocations: state.locations });
}


interface LocationState {
    locations: Types.Location[];
    upsertLocation: (location: Types.Location, isNew?: Boolean) => void;
    fetchLocations: () => void;
    deleteLocation: (id: number) => void;
    refreshLocation: (id: number) => void;

    filteredLocations: Types.Location[];
    attrFilters: Types.Filter[];
    setAttrFilters: (attrFilters: Types.Filter[]) => void;

    toggleWatch: (locationId: number, userRef: string) => void;

    view: (Types.View);
    changeView: (view: Types.View, skipLocations: boolean) => void;

    // fetchAttributes: (locationId: number) => void;

    restrictToBounds: boolean;
    setRestrictToBounds: (value: boolean) => void;

    showList: boolean;
    setShowList: (value: boolean) => void;

    showLocationType: string;
    setShowLocationType: (type: string) => void;
}

export const useLocationsStore = create<LocationState>((set) => ({
    locations: [],
    view: {owner: 'project', type: 'all'}, // loading defaults - @TODO move to config

    // fetchAttributes: async (location_id) => {
    //     const { data, error } = await supabase
    //         .from('attributes')
    //         .select('*')
    //         .eq('location_id', location_id)
    //         .eq('active', true)
    //         .is('deleted_at', null)
    //         .order('name');

    //     if (error) {
    //         console.error('Error fetching attributes:', error);
    //         return;
    //     }

    //     // find this location in the locations array and update the attributes
    //     set((state) => {
    //         const updatedLocations = state.locations.find((l) => l.id === location_id)
    //             ? state.locations.map((l) => l.id === location_id ? { ...l, attributes: data } : l)
    //             : state.locations;
    //         return { locations: updatedLocations };
    //     });
    // },

    changeView: async (view, skipLocations) => { 
        const oldView = useLocationsStore.getState().view;

        set((state) => {
            return { 
                view: { ...state.view, ...view }
            };
        });

        // compare the old view with the new view
        if (oldView.owner !== view.owner || oldView.type !== view.type) {
            // reload the locations from the database with the new view

            if (!skipLocations) {
                const data = await getLocations();
                set({ locations: data });
                copyLocationsToFiltered();
            }

            set({ attrFilters: [] }); 
        }
    },

    // Upsert location and update both Supabase and the state
    upsertLocation: async (location: Types.Location, isNew?: Boolean) => {
        isNew = isNew || false;

        const upsertedLocation = await upsertSupabaseLocation(location);

        if (!upsertedLocation || upsertedLocation.length === 0) {
            console.error('Error upserting location in Supabase');
            return null;
        }

        // Update the store with the upserted location (immutably)
        set((state) => {
            const updatedLocations = state.locations.some((l) => l.id === upsertedLocation.id)
                ? state.locations.map((l) => l.id === upsertedLocation.id 
                    ? { ...l, ...upsertedLocation }
                    : l
                )
                : [...state.locations, upsertedLocation];

            return { locations: updatedLocations };
        });

        if (isNew) {
            // set watchlist for the new location
            const userRef = await getCurrentUser();
            if (userRef) {
                // get useAttributesStore 
                const { saveAttributes } = useAttributeStore.getState();
                await saveAttributes(upsertedLocation.id);

                await watchLocation(upsertedLocation.id, userRef);
                upsertedLocation.watching = true;
            } else {
                console.error('Error: userRef is undefined');
            }
        }

        // @TODO If is not new, we need to add to the audit log

        copyLocationsToFiltered();

        // Return the upserted location
        return upsertedLocation;
    },

    // Fetch and set locations from Supabase
    fetchLocations: async () => {
        const data = await getLocations();

        set({ locations: data });
        set({ attrFilters: [] });

        copyLocationsToFiltered();

        return data;
    },

    deleteLocation: async (id) => {
        const { error } = await supabase
            .from('locations')
            .update({ deleted_at: new Date().toISOString() })
            .eq('id', id);

        if (error) {
            console.error('Error soft deleting location:', error);
            return;
        }
        
        set((state) => {
            return { locations: state.locations.filter((l) => l.id !== id) };
        });

        copyLocationsToFiltered();
    },

    refreshLocation: async (id) => {
        const location = await getLocation(id);

        if (!location) return;

        set((state) => {
            const updatedLocations = state.locations.some((l) => l.id === location[0].id)
                ? state.locations.map((l) => l.id === location[0].id ? location[0] : l)
                : [...state.locations, location[0]];

            return { locations: updatedLocations };
        });

        copyLocationsToFiltered();
    },

    filteredLocations: [], // some views will use this filtered view
    attrFilters: [],
    setAttrFilters: (attrFilters) => {
        set({ attrFilters });

        // set filteredLocations
        const filtered = filterLocations(useLocationsStore.getState().locations, attrFilters);
        set({ filteredLocations: filtered });
    },

    toggleWatch: async (locationId: number, userRef: string) => {
        await watchLocation(locationId, userRef);
        // reload this location from locations_extended and update the store
        // get the location_extended data record where id = locationId
        
        const location = await getLocation(locationId);

        if (!location) return;

        set((state) => {
            const updatedLocations = state.locations.some((l) => l.id === location[0].id)
                ? state.locations.map((l) => l.id === location[0].id ? location[0] : l)
                : [...state.locations, location[0]];

            return { locations: updatedLocations };
        });
    },

    restrictToBounds: false,
    setRestrictToBounds: (value) => {
        set({ restrictToBounds: value });
    },

    showList: false,
    setShowList: (value) => {
        set({ showList: value });
    },

    showLocationType: 'locations',
    setShowLocationType: (type: string) => set({ showLocationType : type }),
}));
