import { Routes, Route } from 'react-router-dom';
import App  from './App';
import Guest from './Guest';
import FourOFour from './404';
import AccessDenied from './AccessDenied';
import Frame from './Frame';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/guest/:uid/:style?" element={<Guest />} />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<FourOFour />} />
            <Route path="/frame" element={<Frame />} />
        </Routes>
    );
}

export default AppRoutes;