
export default function FourOFour() {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="text-center">
                <h1 className="text-4xl font-bold">404</h1>
                <p className="text-lg">Page not found</p>
            </div>
        </div>
    );
}