import { Types } from '../types';
import { create } from 'zustand';
import { supabase } from '../utils/supabaseClient';

interface AuditState {
    auditLog: Types.Audit[];
    upsertAuditLog: (audit: Types.Audit, tempId: string | null) => void;
    fetchAuditLog: (id: number | null) => void;
    deleteAuditLog: (id: number) => void;

    loading: boolean;
    setLoading: (loading: boolean) => void;
}

async function getAudits(locationId: number) {
    // get audits with user data
    const { data, error } = await supabase
        .from('audits_extended')
        .select(`
            *,
            users:users (first_name, last_name)
        `)
        .eq('location_id', locationId)
        .order('created_at', { ascending: false });

    if (error) {
        console.error('Error fetching audits:', error);
        return [];
    }

    return data;
}

async function upsertSupabaseAudit(audit: Types.Audit) {
    const { data, error } = await supabase
        .from('audits') 
        .upsert(audit, { returning: 'representation', onConflict: ['id'] })
        .select();

    if (error) {
        console.error('Error upserting audit in Supabase:', error);
        return null;
    }
    
    return data;
}

export const useAuditStore = create<AuditState>((set) => ({
    auditLog: [],

    upsertAuditLog: async (audit, tempId) => {
console.log('UPSERTING AUDIT', audit);
        const upsertedAudit = await upsertSupabaseAudit(audit);

console.log('tempId', tempId);
        if (upsertedAudit && upsertedAudit.length > 0) {
            if (tempId) {
                // set the audit__id on attachment rows with the temp_id
                const { data, error } = await supabase
                    .from('attachments')
                    .update({ audit_id: upsertedAudit[0].id, temp_id: null })
                    .eq('temp_id', tempId);
            }

            const data = await getAudits(audit.location_id);
            set({ auditLog: data });

            return upsertedAudit[0];
        } else {
            console.error('Failed to upsert audit in Supabase');
            return null;
        }
    },

    fetchAuditLog: async (locationId: number | null) => {
        const { loading } = useAuditStore.getState();
        if (loading) return;

        set({ loading: true, auditLog: [] }); // Clear data and set loading

        if (locationId === null) {
            set({ loading: false });
            return;
        }

        const data = await getAudits(locationId);
        set({ auditLog: data, loading: false });   
    },

    deleteAuditLog: async (id) => {
        const { error } = await supabase.from('audits').delete().eq('id', id);

        if (error) {
            console.error('Error deleting audit:', error);
            return;
        }
        
        set((state) => {
            return { audits: state.audits.filter((l) => l.id !== id) };
        });
    },

    loading: false,
    setLoading: (loading) => set({ loading }),
}));
