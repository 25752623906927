import { useState, lazy, Suspense } from "react";
import { SlTooltip, SlIcon, SlDialog } from '@shoelace-style/shoelace/dist/react';

const ImageUpload = lazy(() => import('./ImageUpload'));

interface AddImageModalProps {
    location_id: number | null;
}

export default function AddImageModal({ location_id }: AddImageModalProps) {
    const [isUploading, setIsUploading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return( 
        <>
            <div className="flex items-center justify-center h-12 relative">
                <SlTooltip content="Add some photos" placement="top">
                    <SlIcon
                        name="camera" 
                        label="Add image" 
                        className="absolute -top-1 -right-2 md:right-0 text-xl p-2 bg-blue-500 text-white hover:text-gray-200 rounded-full shadow-lg cursor-pointer" 
                        onClick={() => setIsOpen(true)}
                    />
                </SlTooltip>
            </div>

            <SlDialog 
                open={isOpen} 
                onSlAfterHide={() => setIsOpen(false)}
                noHeader={true}
            >
                <Suspense fallback={ <p>Loading...</p> }>
                    <ImageUpload 
                        temp_id={ null } 
                        location_id={ location_id || null } 
                        setIsUploading={ setIsUploading }
                    />
                </Suspense>
            </SlDialog>
        </>
    );
}