import { useRef, useEffect, useState, useMemo } from 'react';
import { SlCarousel, SlCarouselItem, SlIconButton } from '@shoelace-style/shoelace/dist/react';
import { useImageStore } from 'src/stores/imageStore';
import ConfirmationDialog from 'src/partials/ConfirmationDialog';
import { formatDate } from 'src/utils/utils';
import 'src/styles/carousel.css';

const imagePath = process.env.REACT_APP_SUPABASE_IMAGE_BUCKET;

interface FullCarouselProps {
    images: any[];
    hideCarousel: () => void;
    goto: number;
}

export default function FullCarousel({ images, hideCarousel, goto }: FullCarouselProps) {
    const { deleteImage, getAllImageCredits } = useImageStore((state) => state);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const carouselRef = useRef<HTMLDivElement>(null); // Updated to target the wrapper div

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (
                carouselRef.current &&
                typeof carouselRef.current.querySelector === 'function' &&
                images.length > 0
            ) {
                const slCarousel = carouselRef.current.querySelector('sl-carousel');
                if (slCarousel && typeof slCarousel.goToSlide === 'function') {
                    slCarousel.goToSlide(goto);
                }
            }
        }, 0); // Small delay ensures the DOM is stable

        return () => clearTimeout(timeout);
    }, [goto, images]);

    useEffect(() => {
        getAllImageCredits();
        
        // hide on click outside
        const handleClickOutside = (e: MouseEvent) => {
            const ignoreTags = ['IMG', 'SL-BUTTON', 'SL-ICON-BUTTON', 'SL-ICON'];
            if (!ignoreTags.includes(e.target.tagName)) hideCarousel();
        };

        document.addEventListener('mousedown', handleClickOutside); // Use `mousedown` for better control
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const close = () => {
        hideCarousel();
    };

    const openDeleteConfirmation = (e: React.MouseEvent, id: any) => {
        e.stopPropagation();
        setImageToDelete(id);
        setDialogOpen(true);
    };

    const confirmDelete = () => {
        deleteImage(imageToDelete);
        setDialogOpen(false);
        setImageToDelete(null);
    };

    const cancelDelete = (e) => {
        setDialogOpen(false);
        setImageToDelete(null);
    };

    const ImageElements = useMemo(() => {
        return images.map((img, index) => (
            <div className="relative h-full object-contain" key={index}>
                <img
                    alt={img.txt}
                    src={imagePath + img.url}
                    className="cursor-pointer w-full h-auto max-h-full object-contain rounded-lg"
                />

                <div className="absolute bottom-0 w-full bg-black opacity-60 text-white py-1 px-2 text-sm flex justify-between">
                    <div>Credit: { img.credit || 'Unknown' }</div>
                    <div>Uploaded: { formatDate(img.created_at) }</div>
                </div>
                
                <div className="image-carousel__delete text-xl absolute top-2 right-2 bg-gray-800 hover:bg-opacity-60 bg-opacity-30 rounded-full transition-opacity">
                    <SlIconButton
                        name="trash"
                        label="Delete"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent the event from reaching the document listener
                            console.log(e, img.id); // Your desired logic
                            openDeleteConfirmation(e, img.id); // Open the confirmation dialog
                        }}
                        className="text-gray-200"
                    />
                </div>
            </div>
        ));
    }, [images]);

    return (
        <div
            ref={carouselRef}
            className="full-carousel fixed top-0 left-0 px-0 w-screen h-screen bg-black bg-opacity-80 z-50 flex justify-center items-center"
        >
            {images.length && (
                <>
                    <SlCarousel
                        className="full-carousel w-full h-full"
                        navigation
                        loop
                        style={{ '--aspect-ratio': 1.1 }}
                        mouseDragging={true}
                    >
                        {ImageElements.map((img, index) => (
                            <SlCarouselItem key={index} className="h-full p-4">
                                {img}
                            </SlCarouselItem>
                        ))}
                    </SlCarousel>
                </>
            )}

            <ConfirmationDialog
                isOpen={isDialogOpen}
                onConfirm={confirmDelete}
                onCancel={cancelDelete}
                message="Are you sure you want to delete this image?"
            />

            <div className="absolute bottom-0 right-0 p-4">
                <SlIconButton
                    name="x-circle-fill"
                    onClick={close}
                    className="text-commspot_pale text-3xl"
                />
            </div>
        </div>
    );
}
