import { useCallback } from 'react';
import { Types } from '../types';
import useProjectStore from "../stores/projectStore";
import { prepareTypes } from "../utils/utils";

import { SlOption, SlSelect } from '@shoelace-style/shoelace/dist/react';
import typesData from "../config/types.json";
const types: Types.TypeConfig = typesData;

interface LocationTypePickerProps {
    location: Types.Location;
    setLocation: (location: Types.Location) => void;
    showLabel?: boolean;
    mini?: boolean;
}

export default function LocationTypePicker({ location, setLocation, showLabel=true, mini=false }: LocationTypePickerProps) {
    const { currentProject } = useProjectStore((state) => state);

    const handleTypeChange = useCallback((e) => {
        setLocation({ ...location, type: e.target.value });
    }, [location, setLocation]);

    const label = showLabel ? 'Type' : '';

    const preparedTypes = prepareTypes(types, currentProject);
    const sortedTypes = preparedTypes.sort();

    // if there's only one type, set it as the default
    if (!location.type && sortedTypes.length === 1) {
        setLocation({ ...location, type: sortedTypes[0] });
    }

    return (
        <SlSelect 
            label={ label }
            className={ mini ? 'mb-0' : 'mb-4' }
            value={ location.type }
            onSlChange={ handleTypeChange }
            hoist={true}
            placeholder="What type of location is this?"
            size={ mini ? 'small' : 'medium' }
        >   
            { sortedTypes.map((type) => (
                <SlOption key={ type } value={ type }>
                    { types[type]?.label }
                </SlOption>
            ))}
        </SlSelect>
    );
}