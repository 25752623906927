import Logo from '../partials/Logo';
import Name from '../partials/Name';
import useAppStore from 'src/stores/appStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@awesome.me/kit-5d73c3b0cb/icons/classic/light';
import ProjectName from 'src/partials/ProjectName';
import UserAvatar from 'src/partials/UserAvatar';

export default function Header() {
    const { setOptionsDrawerOpen, guestMode } = useAppStore((state) => state);

    return (
        <header className="flex text-3xl font-normal bg-commspot_dark w-full text-center text-white h-12 z-20 shadow-xl absolute">
            <div className="py-2 flex">
                <Logo />
                <Name />
            </div>

            <ProjectName />
            <div className="flex-grow"></div>

            { !guestMode &&
                <>
                    <UserAvatar />

                    <div className="px-4 self-center">
                        <FontAwesomeIcon icon={faBars} 
                            onClick={() => setOptionsDrawerOpen(true)}
                            className="cursor-pointer text-2xl"
                        />
                    </div>
                </>
            }
        </header>
    );
}