import { useEffect, useState } from 'react';
import { userInitials, getAvatarUrl } from '../utils/userUtils';
import { useUserStore } from '../stores/userStore';

import SlAvatar from '@shoelace-style/shoelace/dist/react/avatar';

export default function UserAvatar() {
    const { user, fetchCurrentUser } = useUserStore((state) => state);
    const [userAvatar, setUserAvatar] = useState(null);

    useEffect(() => {
        if (!user) fetchCurrentUser();
    }, []);

    useEffect(() => {
        const fetchAvatar = async () => {
            const avatarUrl = await getAvatarUrl();
            setUserAvatar(avatarUrl);
        };
    
        fetchAvatar();
    }, [user]);

    return (
        <div className="flex align-middle py-2 self-center mt-[-1px]">
            <SlAvatar
                className="mr-3"
                initials={ user ? userInitials(user) : null }
                style={{ '--size': '36px' }} 
                image={ userAvatar }
            />
        </div>
    )
}