import { useState } from 'react';
import { Types } from 'src/types';
import { config } from 'src/config/app';
import { formatDateTime } from 'src/utils/utils';
import SlRelativeTime from '@shoelace-style/shoelace/dist/react/relative-time';
import Lightbox from 'src/components/image/Lightbox';

interface AuditEntryProps {
    audit: Types.Audit;
    isEven: boolean;
}

export default function AuditEntry({ audit, isEven }: AuditEntryProps) {
    const [showLightbox, setShowLightbox] = useState(false);
    const name = audit.users && `${audit.users.first_name} ${audit.users.last_name}`;
    const path = config.files.image_path;

    return (
        <tr className={ `border-b  ${isEven ? 'bg-commspot_pale' : 'bg-commspot_paler'}` }>
            <td className="px-4  py-3" colSpan={4}>
                <div className="flex justify-between mb-1">
                    <div className="text-xs font-semibold">
                        <span>{ formatDateTime(new Date(audit.created_at)) }</span>
                        <span> - </span>
                        <span className="text-xs">
                            <SlRelativeTime date={ audit.created_at } sync />
                        </span>
                    </div>
                    <div className="text-xs font-light text-gray-500">
                        by { name }
                    </div>
                </div>

                <p className="text-gray-700">   
                    { audit.notes }
                </p>

                { audit.changes && audit.changes.length &&
                    <div>
                        { audit.changes.map((change, index) => (
                            <div key={ index } className="text-xs text-gray-500">
                                <span className="font-semibold capitalize">{ change.name }</span>
                                <span> to </span>
                                <span className="font-semibold">{ change.value }</span>
                                { !isNaN(change.value) && change.estimated && <span> (est)</span> }
                            </div>
                        )) }
                    </div>
                }   

                { audit.attachments && audit.attachments[0].url &&
                    <div>
                        { audit.attachments.map((attachment, index) => (
                            <div key={ index } className="text-xs text-gray-500">
                                <img 
                                    src={ `${path}/${attachment.url}` } 
                                    alt={ attachment.url } 
                                    className="h-20 rounded cursor-pointer"
                                    onClick={ () => setShowLightbox(true) } 
                                />

                                { showLightbox &&
                                    <Lightbox 
                                        image={ attachment } 
                                        closeLightbox={ () => setShowLightbox(false) } 
                                        imagePath={ path }
                                    />
                                }
                            </div>
                        )) }
                    </div>
                }
            </td>
        </tr>
    );
}