export const config = {
    // map configuration
    default_map_style: 'standard',
    map_options: {
        standard:{
            url: 'mapbox://styles/mapbox/standard',
            icon: 'compass'
        },
        satellite: {
            url: 'mapbox://styles/mapbox/standard-satellite',
            icon: 'globe-europe-africa'
        },
        streets: {
            url: 'mapbox://styles/mapbox/streets-v12',
            icon: 'signpost-split'
        },
        outdoors: {
            url: 'mapbox://styles/mapbox/outdoors-v12',
            icon: 'tree'
        },
        light: {
            url: 'mapbox://styles/mapbox/light-v11',
            icon: 'map'
        },
        dark: {
            url: 'mapbox://styles/mapbox/dark-v11',
            icon: 'map-fill'
        },
    },

    // file upload configuration
    files: {
        storage_url: 'https://qyxcfcokpmunlemqbegb.supabase.co/storage/v1/object/tus',
        bucket: 'images',
        image_path: process.env.REACT_APP_SUPABASE_IMAGE_BUCKET,
    },

    mobile_breakpoint: 768,
    long_click_duration: 400,
};