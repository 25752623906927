import { useEffect, lazy, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { config } from 'src/config/app';
import './App.css';
import { getProjectByUID } from './utils/utils';
import useProjectStore from './stores/projectStore';
import { useMapStore } from 'src/stores/mapStore';
import { useLocationsStore } from 'src/stores/locationStore';

import Header from './components/Header';
import Modal from './components/Modal';

// Shoelace CSS
import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.17.1/cdn/');

const LocationDrawer = lazy(() => import('./components/locations/LocationDrawer'));
const CSMap = lazy(() => import('./components/map/Map'));

function Guest() {
    const { uid, style } = useParams();
    const { setCurrentProject } = useProjectStore((state) => state);
    const { setStyle } = useMapStore((state) => state);
    const { fetchLocations } = useLocationsStore((state) => state);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const project = await getProjectByUID(uid);

            if (!project) {
                navigate('/404');
                return;
            }

            if (project.access !== 'public') {
                navigate('/access-denied');
                return;
            }

            if (style && config.map_options[style]) {
                setStyle(style);
            }

            await setCurrentProject(project);
            await fetchLocations();
        })();
    }, [uid]);

    return (
        <div className="App">
            <Header />

            <main className="App-main">
                <Suspense fallback={<p>Loading...</p>}>
                    <LocationDrawer guestMode={true} />
                </Suspense>

                <CSMap />
            </main>

            <Modal />
        </div>
    );
}

export default Guest;