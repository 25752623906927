import { supabase } from '../utils/supabaseClient';
import { Types } from '../types';

export const getUserSession = async () => {
    const session = await supabase.auth.getSession();
    return session.data?.session?.user
}

export const getCurrentUser = async () => {
    const user = await getUserSession();
    return user ? user.id : null;
}

export const getUserRecord = async (userRef: string) => {
    const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_ref', userRef)
        .single();

    if (error) {
        console.error('Error fetching user:', error);
        return null;
    }

    return data;
}

export const userInitials = (user: Types.User) => {
    if (user?.first_name) return user.first_name[0] + user.last_name[0];

    return '';
}

export const getAvatarUrl = async () => {
    const user = await getUserSession();
    if (!user || !user.user_metadata) return null;

    return user.user_metadata.avatar_url;
}
    
export const projectInitials = (currentProject?: Types.Project) => {
    if (!currentProject) return '';
    return currentProject.name[0];
}

export const getCurrentProject = async () => {
    const project = localStorage.getItem('project');
    return project ? JSON.parse(project) : null;
}

export const setCurrentProject = async (project: String) => {
    localStorage.setItem ('project', JSON.stringify(project));
}

export const isUserOrOwnsProject = async (userRef: String, projectId: Number) => {
    // check if the user ref is the logged in user or they own the project
    const thisUserRef = await getCurrentUser();

    if (userRef !== thisUserRef) return true;

    // check if the user owns the project
    const { data, error } = await supabase
        .from('projects')
        .select('id')
        .eq('owner', userRef)
        .eq('id', projectId);

    if (error) {
        console.error('Error fetching projects:', error);
        return false;
    }

    return data && data.length > 0;
}

export const createInviteLink = async (projectId: number, expiry_days: number, type: string, email: string) => {
    // create a unique 16 char token for the invite link
    const token = Math.random().toString(36).substring(2, 18);

    // set expiry timestamp today + expiry_days
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + expiry_days);

    // create a new invite record in the project
    const { error } = await supabase
        .from('invites')
        .insert([{ 
            token: token, 
            type: 'project',
            type_id: projectId,
            expiry: expiry,
            ref_type: type,
            email: email
        }]);

    if (error) {
        console.error('Error creating invite:', error);
        return null;
    }

    return `https://app.commspot.org/?invite=${token}`;
}
export const getUserNames = async (userRefs: string[]) => {
    // make sure the userRefs are unique
    userRefs = [...new Set(userRefs)];

    const users = await supabase
        .from('users')
        .select('user_ref, first_name, last_name')
        .in('user_ref', userRefs);

    if (users.error) {
        console.error('Error fetching user names:', users.error);
        return [];
    }

    return users.data
}

/* check if there are any invites for the user by email address */
export const checkForInvites = async (email: string) => {
    const invites = await supabase
        .from('invites')
        .select('users(first_name, last_name), expiry, projects(name), token')
        .eq('email', email)
        .gt('expiry', new Date().toISOString())
        .is('accepted', null)
        .eq('type', 'project');

    if (invites.error) {
        console.error('Error fetching invites:', invites.error);
        return [];
    }

    return invites.data;
}

export const handleInvite = async (token: string, accept: boolean) => {
    const invite= await supabase
        .from('invites')
        .select('type_id')
        .eq('token', token)
        .single();

    if (invite.error) {
        console.error('Error fetching invite:', invite.error);
        return null;
    }

    if (!invite.data) {
        console.error('Invite not found');
        return null;
    }

    const { error: inviteError } = await supabase
        .from('invites')
        .update({ accepted: accept })
        .eq('token', token);

    if (inviteError) {
        console.error('Error updating invite:', inviteError);
        return null;
    }

    // add the user to the project
    if (accept) {
        const user = await getUserSession();

        const { error: projectError } = await supabase
            .from('project_user')
            .insert([{ user_ref: user.id, project_id: invite.data.type_id }]);

        if (projectError) {
            console.error('Error adding user to project:', projectError);
            return null;
        }
    }

    return invite;
}